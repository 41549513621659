import { A } from "@solidjs/router";
import { Title } from "@solidjs/meta";
import { useAppState } from "~/AppContext";

export default function About() {
  const { t } = useAppState();
  return (
    <main class="text-center mx-auto text-gray-700 p-4">
      <Title>{t("about")}</Title>
      <h1 class="max-6-xs text-6xl text-sky-700 font-thin uppercase my-16">
        {t("about")}
      </h1>
    </main>
  );
}
